import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Fragment, useEffect, useState } from 'react';
import { Collapse, LinearProgress } from '@mui/material';
import React from 'react';
import { GLOBAL_REPORT_CATEGORIES, ReportsType, USER_REPORT_CATEGORIES, reportOrderComparator } from './reports/common/MyReports';
import { Report, useClient } from '../api'
import { Icon } from './common/Icon';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication';
import { useDomainConfig } from '../context';
import useTranslate from './common/useTranslate';


function ReportListItem({ report }: { report: Report }) {
    const navigate = useNavigate();
    return <ListItemButton sx={{ pl: 8 }} onClick={() => navigate(`/app/${report.admin_only ? "global-reports" : "individual-reports"}/${report.name}`)}>
        <ListItemIcon>
            <Icon name={report.icon} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary={report.title} />
    </ListItemButton>
}

function ReportCategoryList({ category, reports }: { category: any, reports: Report[] }) {
    const [open, setOpen] = useState(false);
    const translate = useTranslate();
    
    const reportsForThisCategory = reports.filter(report => report.category === category.name).sort(reportOrderComparator);

    const handleClick = () => {
        setOpen(!open);
    };

    return (<React.Fragment>
        <ListItemButton onClick={handleClick} sx={{ pl: 4 }} disabled={reportsForThisCategory.length === 0}>
            {/* <ListItemIcon>
                <InboxIcon />
            </ListItemIcon> */}
            <ListItemText primary={translate(category.name_key)} />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {reportsForThisCategory.map(report => <ReportListItem key={report.name} report={report} />)}
            </List>
        </Collapse>
    </React.Fragment>
    )
}

function ReportsList({ type }: { type: ReportsType }) {
    const [reports, setReports] = useState<Report[]>();
    const [open, setOpen] = useState(false);
    const { listReports } = useClient();
    const navigate = useNavigate();
    const { user } = useAuth();
    const translate = useTranslate();
    const domainConfig = useDomainConfig();

    const disabled = (type === ReportsType.ADMIN)
        ? (!user.isAdmin)
        : (!(domainConfig?.modules.my_gsi.enabled))

    const handleClick = (e: any) => {
        setOpen(!open);
        e.stopPropagation();
    };

    useEffect(() => {
        const reportFilter = (report: Report) => report.admin_only === (type === ReportsType.ADMIN);
        listReports().then(
            res => res.filter(reportFilter)
        ).then(setReports);
    }, [type]);

    if (!reports)
        return <LinearProgress />;

    return (<React.Fragment>
        <ListItemButton onClick={handleClick} disabled={disabled}>
            {/* <ListItemIcon>
                <InboxIcon />
            </ListItemIcon> */}
            <Box display="flex" minWidth="100%">
                <ListItemText
                    sx={{flexGrow: 0}}
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/app/${type === ReportsType.ADMIN ? "global-reports" : "individual-reports"}`);
                    }}
                    primary={type === ReportsType.ADMIN ? translate("globalReports.name") : translate("endUser.name")}
                />
                <Box
                    flexGrow="1"
                    component="span"
                    display="flex"
                    justifyContent="flex-end"
                >
                    {open ? <ExpandLess/> : <ExpandMore />}
                </Box>
            </Box>
            
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {(type === ReportsType.ADMIN ? GLOBAL_REPORT_CATEGORIES : USER_REPORT_CATEGORIES)
                    .map(category => <ReportCategoryList key={translate(category.name_key)} category={category} reports={reports} />)}
            </List>
        </Collapse>
    </React.Fragment>)
}

export default function DrawerMenu() {
    const navigate = useNavigate();
    const {user} = useAuth();
    const translate = useTranslate();
    const domainConfig = useDomainConfig();
    const width = "300px";
    return (
        <Drawer
            variant="permanent"
            sx={{
                width: width,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: width, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                {
                    <Fragment>
                        <ReportsList type={ReportsType.ADMIN} />
                        <Divider />
                    </Fragment>
                }
                <ReportsList type={ReportsType.USER} />
                {
                    <Fragment>
                        <Divider />
                        <ListItemButton
                            disabled={!user.isAdmin || !domainConfig?.modules.shared_drive_security.enabled}
                            onClick={() => navigate("/app/security-and-alerting")}
                        >
                            <ListItemText primary={translate("SDSecurity.name")} />
                        </ListItemButton>
                    </Fragment>
                }

            </Box>
        </Drawer>
    );
}