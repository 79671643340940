import { Box, Card, CardActionArea, CardContent, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../authentication";
import { useDomainConfig } from "../context";
import useTranslate from "./common/useTranslate";

function ModuleCard({link, title, description, KPIs, disabled}: {link: string, title: string, description: string, KPIs: string[], disabled?: boolean}) {
    const navigate = useNavigate();
    const translate = useTranslate();

    return (
        <Tooltip placement="top" title={disabled && <div style={{whiteSpace: "pre-line"}}>{translate("auth.module_disabled")}</div>} >
            <Card sx={{borderRadius:"8px", width: 350,  margin: "8px", display: "flex"}}> 
            <CardActionArea
                onClick={() => navigate(link)}
                sx={{flexGrow: "1", display: "flex", flexDirection: "column", ...(disabled && {opacity: 0.38, pointerEvents: "none"})}}
            >
                <CardContent sx={{flexGrow: "1", padding: "16px 24px 16px 16px"}}>
                    <Typography variant="h6" className="darkblueHeader" fontWeight="bold" mb={1}>
                        {title}
                    </Typography>
                    <Box minHeight="85px">
                        <Typography variant="body2" className="greyBodyText">
                            {description}
                        </Typography>
                    </Box>
                    <Typography variant="body2" className="greyBodyText">
                        {/* {translate("homePage.includedKPIs")} */}
                        <ul style={{marginTop: "4px"}}>
                            {KPIs.map(KPI => <li key={KPI}>{KPI}</li>)}
                        </ul>
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </Tooltip>
    );
}

export default function HomePage() {
    const { user } = useAuth();
    const domainConfig = useDomainConfig();
    const translate = useTranslate();

    useEffect(() => {
        document.title = "GSI - Home"
    }, []);
    return <Box height="100%" display="flex" flexDirection="column">
        <Box flexGrow="1" display="flex" justifyContent="center" alignItems="center" maxHeight={600}>
            <Box>
                <Typography variant="h5" className="greyBodyText" mb="28px">{translate("homePage.welcomeMessage")}</Typography>
                <Box display="flex" maxWidth="1200px" mt="16px">
                    {
                        <ModuleCard
                            title={translate("globalReports.name")}
                            link="/app/global-reports"
                            disabled={!user.isAdmin}
                            description={translate("globalReports.description")}
                            KPIs={[
                                translate("globalReports.KPIs.adoption"),
                                translate("globalReports.KPIs.security"),
                                translate("globalReports.KPIs.support"),
                                translate("globalReports.KPIs.carbon_footprint"),
                            ]}/>
                    }
                    <ModuleCard
                        title={translate("endUser.name")}
                        link="/app/individual-reports"
                        disabled={!domainConfig?.modules.my_gsi.enabled}
                        description={translate("endUser.description")}
                        KPIs={[
                            translate("endUser.KPIs.statistics"),
                            translate("endUser.KPIs.comparison"),
                            translate("endUser.KPIS.usage"),
                        ]}
                    />
                    {
                        <ModuleCard
                                title={translate("SDSecurity.name")}
                                link="/app/security-and-alerting"
                                disabled={!user.isAdmin || !domainConfig?.modules.shared_drive_security.enabled}
                                description={translate("SDSecurity.description")}
                                KPIs={[
                                    translate("SDSecurity.KPIs.security")
                                ]}
                            />
                    }
                </Box>
            </Box>
        </Box>
    </Box>
}